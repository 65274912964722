<template>
  <div class="finance">
    <div
      class="box1"
      :style="{
        background: `url(${$imgurl + banner[0].photo.url}) 0 0/cover no-repeat`,
      }"
    >
      <div class="btn" @click="tolinks">
        <img src="../assets/img01/btn.png" alt="" />
        <p>直播回看</p>
      </div>
    </div>

    <div
      class="box2"
      :style="{
        background: `url(${
          $imgurl + background.url
        })center center/cover no-repeat`,
      }"
    ></div>

    <div
      class="box3"
      :style="{
        background: `url(${$imgurl + t_background})0 0/cover no-repeat`,
      }"
    >
      <img class="b3" src="../assets/img01/box3.png" alt="" />
      <div class="swiper_banner">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in guest"
              :key="index"
              :style="`background:url(${
                $imgurl + back
              }) center 0/cover no-repeat`"
            >
              <div class="top">
                <img :src="item.photo ? $imgurl + item.photo.url : ''" alt="" />
              </div>
              <div class="bottom">
                <div class="txt">
                  <p>{{ item.title }}</p>
                  <p>{{ item.subtitle }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>

    <div
      class="box4"
      :style="{
        background: `url(${$imgurl + s_background})0 0/cover no-repeat`,
      }"
    >
      <img class="b4" src="../assets/img01/box4.png" alt="" />
      <div class="box4_swiper">
        <div class="swiper-containers" ref="swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in meeting"
              :key="index"
            >
              <div class="sheet">
                <div
                  class="head"
                  :style="`background:url(${
                    $imgurl + item.photo.url
                  })0 0/ cover no-repeat`"
                ></div>
                <div
                  class="content"
                  v-for="(item2, index2) in item.child"
                  :key="index2"
                >
                  <div>
                    <p>{{ item2.title }}</p>
                    <p>{{ item2.exp1 }}</p>
                  </div>
                  <div v-html="item2.description"></div>
                  <div v-html="item2.content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="swiper-button-prevs"
          v-show="meeting.length >= 2"
          ref="prevs"
        ></div>
        <div
          class="swiper-button-nexts"
          v-show="meeting.length >= 2"
          ref="nexts"
        ></div>
      </div>
    </div>

    <div class="box5">
      <img class="b5" src="../assets/img01/box5.png" alt="" />
      <div class="sam_box">
        <div
          class="list"
          v-for="(item, index) in plan"
          :key="index"
          :style="`background:url(${
            $imgurl + item.photo.url
          }) 0 0/cover no-repeat`"
        >
          <p class="txt2" v-html="item.content">
            数智中国正全力推进，金融行业数字化转型深入发展数智中国正全力推进，金融行业数字化转型深入发展
            数智中国正全力推进，金融行业数字化转型深入发展
            数智中国正全力推进，金融行业数字化转型深入发展
            数智中国正全力推进，金融行业数字化转型深入发展
            数智中国正全力推进金融行业数字化转型深入发展
            数智中国正全力推进，金融行业数字化转型深入发展
          </p>
        </div>
      </div>
    </div>

    <div class="box6">
      <img class="b6" src="../assets/img01/box6.png" alt="" />
      <div class="numimg">
        <div class="image" v-for="(item, index) in logo" :key="index">
          <!-- :style="{
            background: `url(${$imgurl + item.photo.url})0 0/cover no-repeat`,
          }" -->
          <img :src="$imgurl + item.photo.url" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      img: [
        require("@/assets/img01/b1.png"),
        require("@/assets/img01/b2.png"),
        require("@/assets/img01/b3.png"),
      ],
      bg: [
        require("@/assets/img01/sa1.png"),
        require("@/assets/img01/sa2.png"),
        require("@/assets/img01/sa3.png"),
        require("@/assets/img01/sa4.png"),
      ],
      swiper: null,
      swipers: null,
      back: null,
      banner: [
        {
          photo: { url: "" },
        },
      ],
      background: [],
      t_background: [
        {
          photo: {
            url: "",
          },
        },
      ],
      guest: [],
      logo: null,
      s_background: null,
      plan: null,
      meeting: [
        {
          photo: {
            url: null,
          },
        },
        {
          photo: {
            url: null,
          },
        },
      ],
      s_banner: null,
      url: this.$route.params.url,
    };
  },
  created() {
    // console.log(this.url);
  },
  mounted() {
    this.getda();
  },
  watch: {
    $route: "getda",
  },
  methods: {
    getda() {
      this.$fetch({
        url: "index.php/meetings/" + this.url,
        type: "get",
      }).then((res) => {
        this.banner = res.data.banner;
        this.background = res.data.p1_photo;
        this.t_background = res.data.p2_photo.url;
        this.back = res.data.p2_jb_photo.url;
        this.guest = res.data.p2_guest;
        this.s_background = res.data.p3_photo.url;
        this.meeting = res.data.p3_meeting;
        this.plan = res.data.p4_plan;
        this.logo = res.data.p5_logo;
        this.$nextTick(() => {
          this.getswiper();
          this.getswipers();
        });
      });
    },
    //数据请求
    // getdata() {
    //   this.$fetch({
    //     url: "index.php/meeting",
    //     type: "get",
    //   }).then((res) => {
    //     this.banner = res.data.banner;
    //     this.background = res.data.background.photo;
    //     this.t_background = res.data.t_background.photo.url;
    //     this.back = res.data.jb_bj.photo.url;
    //     this.s_background = res.data.s_background.photo.url;
    //     this.guest = res.data.guest;
    //     this.logo = res.data.logo;
    //     this.plan = res.data.plan;
    //     this.meeting = res.data.meeting;
    //     this.s_banner = res.data.s_banner;
    //     this.$nextTick(() => {
    //       this.getswiper();
    //       this.getswipers();
    //     });
    //   });
    // },
    getswiper() {
      this.swiper = new Swiper(".swiper-container", {
        // loop: true,
        slidesPerView: 3,
        spaceBetween: 60,
        // 如果需要前进后退按钮
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
      });
    },
    getswipers() {
      this.swipers = new Swiper(this.$refs.swiper, {
        // loop: true,
        // 如果需要前进后退按钮
        nextButton: this.$refs.nexts,
        prevButton: this.$refs.prevs,
      });
    },
    tolinks() {
      window.open("https://live.polyv.cn/watch/2521962");
    },
  },
};
</script>

<style scoped>
/* .finance {
  margin-top: 72px;
} */
.box1 {
  width: 100%;
  height: 541px;
  background: url(../assets/img01/banner_new.png) 0 0 / cover no-repeat;
}
.box1 .btn {
  position: absolute;
  top: 360px;
  left: 860px;
  width: 199px;
  height: 58px;
  background: linear-gradient(
    214deg,
    #efea91 0%,
    #ff7b52 35%,
    #ea406b 59%,
    #535fd5 100%
  );
  border-radius: 4px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box1 .btn img {
  width: 32px;
  height: 32px;
  margin-right: 14px;
}
.box1 .btn p {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 33px;
}

@media (max-width: 1440px) {
  .box1 .btn {
    top: 271px;
  }
}
@media (max-width: 1366px) {
  .box1 .btn {
    top: 257px;
  }
}
@media (min-width: 1200px) {
  .box2 {
    width: 100%;
    height: 886px;
    background: url(../assets/img01/box2_bg.png) center center / cover no-repeat;
    text-align: center;
  }
  .box2 .b2 {
    width: 286px;
    height: 77px;
    margin: 100px auto 50px;
  }
  .box2 h2 {
    font-size: 36px;
    font-weight: 400;
    color: #212223;
    line-height: 50px;
    margin-top: 100px;
  }
  .box2 p {
    font-size: 16px;
    font-weight: 100;
    color: #212223;
    line-height: 22px;
    margin-top: 5px;
    margin-bottom: 50px;
  }
  .box2 .img img {
    width: 339px;
    height: 339px;
    margin: 0 auto;
  }
  .box3 {
    width: 100%;
    height: 668px;
    background: url(../assets/img01/banner_new3.png) 0 0 / cover no-repeat;
    text-align: center;
    padding-top: 96px;
  }
  .box3 .b3 {
    width: 286px;
    height: 77px;
    margin: 0 auto 50px;
  }
  .box3 h2 {
    font-size: 36px;
    font-weight: 400;
    color: #ffffff;
    line-height: 50px;
  }
  .box3 > p {
    font-size: 16px;
    font-weight: 100;
    color: #ffffff;
    line-height: 22px;
    margin-top: 5px;
    margin-bottom: 50px;
  }
  .box3 .swiper-container {
    width: 983px;
    height: 354px;
    margin: 0 auto;
  }
  .box3 .swiper-container .top {
    height: 235px;
  }
  .box3 .swiper-container .bottom {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box3 .swiper-container .bottom .txt {
    width: fit-content;
    height: 56px;
  }
  .box3 .bottom .txt p:nth-child(1) {
    font-size: 22px;
    color: #ffffff;
  }
  .box3 .bottom .txt p:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }
  .box3 .swiper_banner {
    width: 963px;
    height: 354px;
    margin: 0 auto;
    position: relative;
  }
  .box3 .top {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box3 .top img {
    width: 160px;
    height: 160px;
  }
  .box3 .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    width: 64px;
    height: 63px;
    background: url(../assets/img01/left_l.png) 0 0 / cover no-repeat;
    left: -117px;
    z-index: 1;
  }
  .box3 .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    width: 64px;
    height: 63px;
    background: url(../assets/img01/right_r.png) 0 0 / cover no-repeat;
    right: -117px;
    z-index: 1;
  }
  .box4 {
    text-align: center;
    /* height: 1546px; */
    min-height: 300px;
    /* background: url(../assets/img01/bg123.png) 0 0 / cover no-repeat; */
    background-size: 100%;
    padding-bottom: 100px;
  }

  .box4 .b4 {
    width: 286px;
    height: 77px;
    margin: 100px auto 0;
  }
  .box4 h2 {
    font-size: 36px;
    font-weight: 400;
    color: #212223;
    line-height: 50px;
    margin-top: 100px;
  }
  .box4 p {
    font-size: 16px;
    color: #212223;
    line-height: 22px;
  }
  .box4 .sheet {
    width: 912px;
    margin: 50px auto 0;
  }
  .box4 .sheet .head {
    padding: 0 50px;
    height: 108px;
    display: flex;
    background: url(../assets/img01/head.png) 0 0 / cover no-repeat;
    align-items: center;
    text-align: left;
  }
  .box4 .sheet .head > div {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
  }
  .box4 .sheet .head > div:nth-child(1) {
    flex: 5;
  }
  .box4 .sheet .head > div:nth-child(2) {
    flex: 4;
  }
  .box4 .sheet .head > div:nth-child(3) {
    flex: 1;
  }
  .box4 .sheet .content {
    min-height: 90px;
    display: flex;
    border-bottom: 1px solid #d2dae3;
    text-align: left;
    padding: 0 15px;
    align-items: center;
  }
  .box4 .sheet .content div {
    font-size: 18px;
    font-weight: 400;
    color: #212223;
    line-height: 25px;
  }
  .box4 .sheet .content > div:nth-child(1) {
    flex: 4.75;
    font-family: PingFangSC-Regular, PingFang SC;
  }
  .box4 .sheet .content > div:nth-child(1) > p:nth-child(1) {
    font-size: 18px;
    color: #212223;
    line-height: 25px;
  }
  .box4 .sheet .content > div:nth-child(1) > p:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    color: #212223;
    line-height: 20px;
  }
  .box4 .sheet .content > div:nth-child(2) {
    flex: 3.6;
  }
  .box4 .sheet .content > div:nth-child(2) >>> p {
    margin: 24px 0;
    font-family: PingFangSC-Regular, PingFang SC;
  }

  .box4 .sheet .content > div:nth-child(3) {
    flex: 2;
  }
  .box4 .sheet .content > div:nth-child(3) >>> p {
    margin: 24px 0;
    font-family: PingFangSC-Regular, PingFang SC;
  }
  .box4 >>> .swiper-containers {
    width: 912px;
    min-height: 300px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
  }
  .box4 .swiper-button-prevs {
    width: 64px;
    height: 63px;
    position: absolute;
    left: -150px;
    background: url(../assets/img01/left_l.png) 0 0 / cover no-repeat;
    z-index: 1;
    top: 50%;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 1px 1px 10px #ccc;
  }
  .box4 .swiper-button-nexts {
    width: 64px;
    height: 63px;
    position: absolute;
    background: url(../assets/img01/right_r.png) 0 0 / cover no-repeat;
    right: -150px;
    top: 50%;
    z-index: 1;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 1px 1px 10px #ccc;
  }
  .box4 .box4_swiper {
    width: 912px;
    margin: 0 auto;
    position: relative;
  }
  .box5 {
    text-align: center;
  }
  .box5 .b5 {
    width: 394px;
    height: 77px;
    margin: 100px auto 0;
  }
  .box5 h2 {
    margin-top: 100px;
    font-size: 36px;
    font-weight: 400;
    color: #212223;
    line-height: 50px;
  }
  .box5 p {
    font-size: 16px;
    font-weight: 100;
    color: #212223;
    line-height: 22px;
  }
  .box5 .sam_box {
    width: 1020px;
    margin: 50px auto 100px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .box5 .sam_box .list {
    width: 480px;
    height: 254px;
    padding: 32px;
    margin-bottom: 32px;
  }
  .box5 .sam_box .list .txt1 {
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    line-height: 37px;
    width: fit-content;
  }
  .box5 .sam_box .list .txt2 {
    margin-top: 15px;
    width: 498px;
    height: 105px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 21px;
    background: linear-gradient(
      243deg,
      #535fd5 0%,
      #ea406b 41%,
      #ff7b52 65%,
      #efea91 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: initial;
  }

  .box6 {
    height: 503px;
    text-align: center;
  }
  .box6 h2 {
    font-size: 36px;
    font-weight: 400;
    color: #212223;
    margin-top: 100px;
  }
  .box6 p {
    font-size: 16px;
    font-weight: 100;
    color: #212223;
  }
  .box6 .numimg {
    width: 1240px;
    height: 176px;
    margin: 50px auto 100px;
    display: flex;
    flex-wrap: wrap;
  }
  .box6 .numimg .image {
    width: 206px;
    height: 71px;
    border-radius: 10px;
    background: pink;
    margin-right: 40px;
    margin-bottom: 36px;
  }
  .box6 .numimg .image img {
    width: 100%;
    height: 100%;
  }
  .box6 .b6 {
    width: 286px;
    height: 77px;
    margin: 0 auto;
  }
}
</style>